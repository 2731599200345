import { render, staticRenderFns } from "./IRLScore.vue?vue&type=template&id=699047bb&scoped=true&"
import script from "./IRLScore.vue?vue&type=script&lang=js&"
export * from "./IRLScore.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "699047bb",
  null
  
)

export default component.exports